import React from "react";
import { MainText } from "../components/MainText";

export const Landing = () => {
  return (
    <>
      <MainText />
    </>
  );
}
